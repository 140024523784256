@import '../../../styles/mixins';
@import '../../../styles/extends';

.filter {
  margin-top: 50px;
  margin-bottom: 50px;

  .container {
    background-color: #f4efef;
    padding: 80px;
    border: 2px solid var(--main-color);
    border-radius: 10px;

    @include xs {
      padding: 20px;
      margin: 0 5px;
    }
  }

  &>div {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    input {
      border-radius: 0px !important;
    }

    div,
    button {
      grid-column: span 2;
      border-radius: 0px;

      @include md {
        grid-column: span 6;
      }
    }

    button {
      @extend %mainBtn;
    }
  }

  @include xs {
    margin-top: 150px;
  }
}