.notFound {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
  img {
    text-align: center !important;
    height: 150px;
    width: 150px;
    margin-bottom: 10px;
  }
  h5 {
    font-weight: 600;
  }
}