@import '../../styles/mixins';
@import '../../styles/extends';

.navbar {
  position: unset !important;
  display: unset !important;
  .topNav {
    background-color: var(--main-color);
    color: #fff;
    padding: 5px 0;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 0px 8px;
        border-left: 1px solid #fff;
        font-size: 0.9rem;
        cursor: pointer;
        text-decoration: none !important;
        font-family: 'Almarai', sans-serif !important;
        &:hover {
          color: var(--secondary-color);
        }
        a {
          color: #fff;
          text-decoration: none !important;
          font-family: 'Almarai', sans-serif !important;
          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }
  }

  .navLinks {
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: -1px;
    background-color: #fff;
    z-index: 999;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.02);
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icons {
      margin: 0 20px;
      a {
        text-decoration: none !important;
      }
      i {
        font-size: 0.9rem;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid var(--dark-color);
        color: var(--dark-color);
        display: grid;
        place-items: center;
        margin: 0 4px;
        cursor: pointer;
        &:hover {
          color: var(--main-color);
          border-color: var(--main-color);
        }
      }
    }

    button {
      @extend %mainBtn;
      padding: 5px 15px;
      font-size: 0.8rem;
      transition: all 0.3s ease-in-out;
    }

    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 0px 8px;
        font-size: 0.9rem;
        cursor: pointer;
        font-weight: 600;
        text-decoration: none !important;
        font-family: 'Almarai', sans-serif !important;
        a {
          text-decoration: none !important;
          font-family: 'Almarai', sans-serif !important;
        }
        &:hover {
          color: var(--main-color);
        }
        a {
          color: var(--text-color);
          &.active {
            color: var(--main-color) !important;
          }
          &:hover {
            color: var(--main-color);
          }
        }
      }
    }

    .logo {
      padding: 5px 0;
      img {
        width: 140px;
        height: 75px;
        object-fit: contain;
      }

    }
  }

  .mobileNav {
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    padding: 10px 0;
    display: none;
    position: sticky;
    top: -1px;
    background-color: #fff;
    z-index: 9999;
    @include md {
      display: block;
    }
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    i {
      width: 35px;
      height: 30px;
      border-radius: 5px;
      background-color: var(--main-color);
      color: #fff;
      display: grid;
      place-items: center;
      cursor: pointer;
    }

    img {
      width: 80px;
    }

    button {
      @extend %btn;
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
      color: #000;
      transition: all 0.3s ease-in-out;
      margin-right: 20px;
      &:hover {
        background-color: transparent;
        border-color: var(--secondary-color);
        color: var(--secondary-color);
      }
    }
  }

  @include md {
    .topNav {
      display: none;
    }
    .navLinks {
      display: none;
    }
  }
}
