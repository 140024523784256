@import '../../styles/mixins';

.services {
  margin: 50px 0 100px;

  h1 {
    margin-bottom: 70px !important;
    img {
      transform: rotate(0deg) !important;
    }
    p {
      font-size: 1.5rem;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
  }

  .service {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: #fff;
    grid-column: span 4;
    @include md {
      grid-column: span 6;
    }
    @include xs {
      grid-column: span 12;
    }
    div {
      text-align: center !important;
    }
    span {
      color: #000 !important;
      opacity: 0.8 !important;
      background-color: transparent !important;
      text-align: center !important;
    }
    &:hover {
      background-color: var(--dark-color);
      color: #fff;
      span {
        color: #fff !important;
      }
    }

    img {
      margin-bottom: 20px;
      width: 100px;
      height: auto;
      margin: 10px auto 30px;
      object-fit: contain;
      @include xs {
        width: 80px;
      }
    }
    h4 {
      font-weight: 600;
      text-align: center !important;
      margin-bottom: 10px;
      font-size: 1.1rem;
    }
    p {
      opacity: 0.8;
      font-size: 0.9rem;
      text-align: center !important;
    }
  }
}