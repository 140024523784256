:root {

  --error-alert: #ee6a6a;
  --error-alert-light: #423a4a;
  --success: #45ca80;
  --success-light: #31444c;
  --alert: #f2b24e;
  --alert-light: #434147;
  --main-color: #D63E27;
  --main-color-light: #313b4f;
  --secondary-color: #F7941D;
  --dark-color: #31465B;
  --default-background: #F8F9FA;
  --secondary-background: #fcfcfc;
  --special-background: #fafafa;
  --text-color: #383e50;
  --border-color: #eee;
  --background-light: #FAFAFA;
  --hover-color: #f3f3f3;



  --light-text-color: #767676;
  --muted-text-color: #a7a7a7;
  --warning: #ff9f43;
  --error: rgb(255, 116, 116);
  --danger: #ea5455;
  --info: #00cfe8;
  --dark-success: #1f9d57;
  --dark-warning: #ff8510;
  --dark-danger: #e42728;
  --dark-info: #00a1b5;
}
