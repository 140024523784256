@import '../../../styles/mixins';
@import '../../../styles/extends';

.blogs {
  margin-bottom: 100px;
  .wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
  }
  .blog {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    padding: 15px;
    margin-bottom: 30px;
    background-color: #fff;
    grid-column: span 3;
    cursor: pointer;
    @include lg {
      grid-column: span 4;
    }
    @include md {
      grid-column: span 6;
    }
    @include sm {
      max-width: 350px;
      margin: 10px auto;
    }
    @include xs {
      grid-column: span 12;
    }

    img {
      height: 200px;
      position: relative;
      z-index: 3;
      border-radius: 5px;
    }

    .info {
      padding: 15px 0 20px;
      position: relative;
      z-index: 3;
      h6 {
        font-weight: 600;
      }
      p {
        opacity: 0.8;
        font-size: 0.9rem;
      }
      span {
        opacity: 0.8;
        font-size: 0.8rem;
        i {
          color: var(--main-color);
        }
        span {
          margin: 0 5px;
        }
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    button {
      @extend %mainBtn;
    }
  }
}