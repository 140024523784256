@import '../../../styles/extends';

.applicationsSection {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 5px;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
    .app {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 15px;
      grid-column: span 4;
      border-bottom: 3px solid var(--main-color);
      @include lg {
        grid-column: span 6;
      }
      @include md {
        grid-column: span 12;
      }
      @include xs {
        grid-column: span 12;
      }
      .side {
        display: flex;
        justify-content: space-around;
        span {
          display: block;
        }
      }
      p, div {
        font-weight: 600;
        padding: 5px 20px;
        border-radius: 5px;
        span {
          font-weight: 400;
          color: var(--main-color);
        }
        h1 {
          font-size: 20px;
          color: #fff !important;
          background-color: var(--main-color);
          padding: 5px 10px;
          border-radius: 5px;
          text-align: center !important;
          * {
            color: #fff !important;
          }
        }
      }
      .note {
        font-weight: 500;
        background-color: #e67e22;
        color: #fff;
        margin-top: 20px;
        i {
          margin: 0 5px;
        }
      }
    }
  }

  .btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      @extend %mainBtn;
    }
  }
}