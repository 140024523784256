@import '../../../styles/mixins';

.clients {
  margin: 50px 0 100px;

  .row {
    @include md {
      flex-direction: column-reverse;
    }
  }

  .carousel {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 5px;
    direction: ltr !important;

    @include xs {
      width: 100%;
      padding: 20px 10px;
    }

    .slide {
      display: flex;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      min-height: 220px;
      @include lg {
        width: 100%;
        height: 240px;
      }
      @include md {
        width: 80%;
        height: 220px;
      }
      @include sm {
        width: 90%;
        height: 240px;
      }
      @include xs {
        width: 100%;
        height: 250px;
      }
      i {
        color: var(--dark-color);
        font-size: 4rem;
      }

      .info {
        margin: 0 40px;

        .user {
          display: flex;
          margin-top: 25px;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }
          div {
            margin: 0 20px;
            h5 {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .text {
    margin-top: 50px;
    @include md {
      width: 90%;
      margin: 30px auto;
      text-align: center !important;
      span, h1, p {
        text-align: center !important;
      }
    }
    @include sm {
      h1 {
        font-size: 1.3rem;
      }
    }
  }
}