@import '../../styles/mixins';
@import '../../styles/extends';

.register {
  margin: 50px 0 100px;

  .info {
    .section {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 40px;
      border-right: 4px solid var(--dark-color);
      background-color: #fff;
      h3 {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        i {
          width: 50px;
          height: 50px;
          background-color: var(--main-color);
          color: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include sm {
            width: 40px;
            height: 40px;
            font-size: 1rem;
          }
        }
        span {
          margin: 0 10px;
          @include md {
            font-size: 1.3rem !important;
          }
          @include sm {
            font-size: 1.2rem !important;
          }
          @include xs {
            font-size: 1.1rem !important;
          }
        }
      }
      p {
        opacity: 0.8;
        @include xs {
          font-size: 0.8rem !important;
        }
      }
      &.en {
        border-right: unset;
        border-left: 4px solid var(--dark-color);
      }
    }
  }

  .block {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.02);
    margin-top: 20px;
    border-right: 2px solid var(--main-color);
    background-color: #fafafa;
    .field {
      margin-bottom: 15px;
      p {
        font-size: 0.8rem;
        color: var(--main-color);
      }
    }
    h4 {
      margin-bottom: 30px;
      font-size: 1.2rem;
      color: var(--main-color);
      display: flex;
      align-items: center;
      i {
        margin: 0 5px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--main-color);
        color: #fff;
        display: grid;
        place-content: center;
        font-size: 1.1rem;
      }
      @include sm {
        font-size: 1rem;
        i {
          margin: 0 5px;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: var(--main-color);
          color: #fff;
          display: grid;
          place-content: center;
          font-size: 1rem;
        }
      }
    }
    label {
      display: block;
      margin-bottom: 5px;
      font-size: 0.9rem;
      font-weight: 600;
      padding: 0 5px;
    }
    input:not([type="radio"]) {
      width: 100%;
      border: 1px solid rgb(230, 230, 230);
      border-radius: 5px;
      padding: 10px 15px;
      font-size: 0.9rem;
      outline: none;
      background-color: #fff;
      &.error {
        border-color: var(--main-color);
      }
    }

    .date {
      position: relative;
      i {
        position: absolute;
        top: 50%;
        left: 15px;
        z-index: 3;
        opacity: 0.8;
        transform: translateY(-50%);
        font-size: 1.2rem;
      }
      div {
        text-align: center !important;
        direction: unset !important;
      }
      &.en {
        i {
          left: unset;
          right: 15px;
        }
      }
    }

    .radio {
      div {
        display: flex;
        span {
          margin: 0 30px
        }
      }

      input:checked {
        background-color: var(--main-color);
        border-color: var(--main-color);
      }
      input:focus {
        box-shadow: unset !important;
      }
    }
    .imageArea {
      height: 250px;
      border: 2px dashed var(--border-color);
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        i {
          font-size: 2.5rem;
          opacity: 0.7;
          text-align: center !important;
          @include sm {
            font-size: 2rem;
          }
        }
        p {
          font-weight: 400;
          opacity: 0.8;
          margin: 0;
        }
      }
      .images {
        .image {
          width: 80px;
          height: 80px;
          border-radius: 5px;
          margin-bottom: 10px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
          i {
            position: absolute;
            top: -20%;
            right: -20%;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: rgb(226, 226, 226);
            font-size: 0.9rem;
            display: grid;
            place-items: center;
            &:hover {
              background-color: var(--main-color);
              color: #fff;
            }
          }
        }
      }
    }

    &.en {
      border-right: unset;
      border-left: 3px solid var(--main-color);
    }
  }

  .err {
    color: var(--main-color);
    font-size: 0.9rem;
  }

  .btn {
    margin: 20px auto;
    width: 75%;
    button {
      @extend %mainBtn;
      width: 100%;
      padding: 10px 20px;
    }
  }
}