@import '../../../styles/mixins';

.knowUs {
  margin-bottom: 100px;

  .video {
    width: 90%;
    margin: 20px auto 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .blobOverlay {
      position: relative;
      z-index: 1;
      width: 450px;
      height: 450px;
      border-radius: 50%;
      background-image: url('../../../../public/assets/images/manyPersons.webp');
      background-size: cover;
      margin: auto;
      animation: blob 5s linear infinite;

      @include sm {
        width: 350px;
        height: 350px;
      }

      @include xs {
        width: 300px;
        height: 300px;
      }

      @keyframes blob {

        0%,
        100% {
          border-radius: 64% 36% 27% 73% / 55% 58% 42% 45%;
        }

        25% {
          border-radius: 73% 27% 26% 38% / 73% 36% 64% 27%;
        }

        50% {
          border-radius: 28% 72% 44% 56% / 49% 40% 60% 51%;
        }

        75% {
          border-radius: 40% 60% 70% 30% / 47% 62% 38% 53%;
        }
      }
    }

    i {
      position: absolute;
      width: 75px;
      height: 75px;
      background-color: var(--main-color);
      color: #fff;
      border-radius: 50%;
      display: grid;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      cursor: pointer;
      z-index: 2;
      animation: animate 1.5s linear infinite;
    }

    @keyframes animate {
      0% {
        box-shadow: 0 0 0 0 var(--main-color);
      }

      40% {
        box-shadow: 0 0 0 20px rgba(255, 26, 67, 0);
      }

      80% {
        box-shadow: 0 0 0 20px rgba(255, 206, 67, 0);
      }

      100% {
        box-shadow: 0 0 0 rgba(255, 206, 67, 0);
      }
    }
  }

  h2 {
    @include sm {
      font-size: 1.3rem;
    }

    @include xs {
      font-size: 1.1rem;
    }
  }

  .features {
    margin-top: 30px;

    div {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 5px;
        background-color: #fff;

        &:hover {
          background-color: var(--dark-color);
          color: #fff;
        }
      }

      i {
        font-size: 2rem;
        color: var(--main-color);
      }

      &:hover {
        i {
          color: #fff;
        }
      }
    }
  }
}