@import '../../../styles/mixins';

.information {
  margin-bottom: 100px;

  .section {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 40px;
    border-right: 4px solid var(--dark-color);
    background-color: #fff;
    h3 {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      i {
        width: 50px;
        height: 50px;
        background-color: var(--main-color);
        color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include sm {
          width: 40px;
          height: 40px;
          font-size: 1rem;
        }
      }
      span {
        margin: 0 10px;
        @include md {
          font-size: 1.3rem !important;
        }
        @include sm {
          font-size: 1.2rem !important;
        }
        @include xs {
          font-size: 1.1rem !important;
        }
      }
    }
    p {
      opacity: 0.8;
      @include xs {
        font-size: 0.8rem !important;
      }
    }
    &.en {
      border-right: unset;
      border-left: 4px solid var(--dark-color);
    }
  }

  .features {
    margin-top: 100px;
    div {
      margin-top: 10px;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 5px;
        background-color: #fff;
        &:hover {
          background-color: var(--dark-color);
          color: #fff;
        }
      }
      i {
        font-size: 2.5rem;
        color: var(--main-color);
        margin-bottom: 5px;
      }
      span {
        font-size: 1.5rem;
      }
      p {
        font-weight: 600;
      }
      &:hover {
        i {
          color: #fff;
        }
      }
    }
  }
}