@import '../../../styles/mixins';

.experiences {
  margin: 50px 0 100px;
  .label {
    margin-bottom: 80px;
  }

  .carousel {
  }

  .text {
    padding-bottom: 100px;
    h3 {
      margin-bottom: 30px;
    }

    .features {
      margin-top: 50px;
      div {
        div {
          border: 1px solid var(--dark-color);
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 10px 0;
          margin-bottom: 10px;
          i {
            font-size: 1.8rem;
            color: var(--dark-color);
          }
          i, p {
            text-align: center;
          }
        }
      }
    }
  }

  .video {
    position: static !important;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
      width: 90%;
      @include sm {
        width: 100%;
      }
    }
    
    i {
      position: absolute;
      width: 75px;
      height: 75px;
      background-color: var(--main-color);
      color: #fff;
      border-radius: 50%;
      display: grid;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      cursor: pointer;
      z-index: 2;
      animation: animate 1.5s linear infinite;
    }
    @keyframes animate {
      0% {
        box-shadow: 0 0 0 0 var(--main-color);
      }
      40% {
        box-shadow: 0 0 0 20px rgba(255, 26, 67, 0);
      }
      80% {
        box-shadow: 0 0 0 20px rgba(255, 206, 67, 0);
      }
      100% {
        box-shadow: 0 0 0 rgba(255, 206, 67, 0);
      }
    }
  }

  .videoViewer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    * {
      pointer-events: unset !important;
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 9999;
      width: 100%;
      height: 100%;
    }

    iframe {
      position: relative;
      width: 50%;
      z-index: 9999999;
      @include md {
        width: 70%;
        height: 300px;
      }
      @include sm {
        width: 80%;
      }
    }
  }
}