.universityInfo {
  .analytics {
    margin-bottom: 50px;
  }
  .one {
    display: flex;
    align-items: center;
    // border: 2px dashed var(--main-color);
    border-radius: 5px;
    // background-color: #fad8d3;
    padding: 15px 20px;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-right: 3px solid var(--main-color);
    img {
      width: 50px;
    }
    div {
      padding: 0 20px;
      span {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--main-color);
        transition: all 0.2s ease-in-out;
      }
      p {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    &:hover {
      transform: scale(1.03);
    }
  }

  h6 {
    margin: 20px 0;
    font-size: 1.1rem;
    font-weight: 600;
    i {
      font-size: 0.9rem;
      margin: 0 5px;
    }
    span {
      color: var(--main-color);
    }
  }

  .data {
    margin-top: 50px;
    ul {
      list-style: circle;
      li {
        font-size: 1.1rem;
        margin-bottom: 10px;
      }
    }
  }

  .info {
    margin: 40px 0;
    .part {
      margin-bottom: 40px;
      h4 {
        color: var(--main-color);
        display: flex;
        align-items: center;
        i {
          width: 40px;
          height: 40px;
          display: grid;
          place-content: center;
          border-radius: 50%;
          background-color: var(--main-color);
          color: #fff;
          margin: 0 5px;
        }
      }
      p {
        font-weight: 600;
      }
    }
  }
}