@import '../../../styles/extends';

.contactForm {
  margin: 50px 0 50px;

  h1 {
    text-align: center !important;
    margin-bottom: 30px;

    img {
      transform: rotate(0deg) !important;
    }

    p {
      font-size: 1.5rem !important;
    }

    @include sm {
      p {
        font-size: 1.2rem !important;
      }
    }
  }

  .row {
    @include md {
      flex-direction: column-reverse;
    }
  }

  .form {
    padding: 40px 20px;
    border-radius: 5px;

    label {
      margin-top: 15px;
      margin-bottom: 5px;
      font-size: 0.9rem;

      span {
        color: var(--main-color);
        font-weight: bold;
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 10px 15px;
      border: 1px solid rgb(225, 225, 225);
      border: 1px solid var(--dark-color);
      border-radius: 5px;
      outline: none;
      font-size: 0.9rem;

      &.error {
        border-color: var(--main-color);
      }
    }

    p:not(.check) {
      font-size: 0.8rem;
      color: var(--main-color);
    }

    textarea {
      width: 100%;
      resize: none;
      height: 200px;
    }

    p {
      display: flex;
      align-items: center;

      input {
        width: 17px;
        height: 17px;
        margin: 0 5px;
        cursor: pointer;
      }
    }

    button {
      @extend %mainBtn;
      width: 200px;
      margin: 20px auto;
    }
  }

  .info {
    margin-top: 50px;
    padding: 40px 20px;
    background-color: var(--dark-color);
    border-radius: 10px;
    color: #fff;

    span {
      font-size: 0.8rem;
    }

    h3 {
      margin-bottom: 20px;
    }

    .wayWrapper {
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      .way {
        grid-column: span 6;

        @include md {
          grid-column: span 2;
        }

        @include sm {
          grid-column: span 3;
        }

        @include xs {
          grid-column: span 6;
        }
      }
    }

    .way {
      display: flex;
      align-items: center;
      margin-top: 20px;

      i {
        width: 50px;
        height: 50px;
        background-color: #fff;
        color: var(--main-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
      }

      div {
        margin: 0 15px;
      }
    }
  }
}