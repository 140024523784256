@import '../../../styles/mixins';
.company {
  margin: 50px 0 100px;

  .row {
    @include md {
      flex-direction: column-reverse;
    }
  }

  .text {
    h2 {
      margin-bottom: 20px;
      @include sm {
        font-size: 1.3rem;
      }
      @include sm {
        font-size: 1.2rem;
      }
    }
    p {
      opacity: 0.8;
      @include sm {
        font-size: 1rem;
      }
      @include sm {
        font-size: 0.9rem;
      }
    }
    h4 {
      margin: 20px 0;
      @include sm {
        font-size: 1.1rem;
      }
      @include sm {
        font-size: 1rem;
      }
    }
  }

  .image {
    position: relative;
    margin-bottom: 50px;
    margin: 0 auto 50px;
    height: 500px;
    width: 100%;

    img {
      position: absolute;
      z-index: 2;
      height: 500px;
      width: 100%;
      object-fit: contain;
    }
  }
}