@import "./mixins.scss";
@import "./variables.scss";

/***************  { General Styles For Elements }   *****************/
$mainColor: #40739e;
$h1: 2rem;
$h2: 1.714rem;
$h3: 1.5rem;
$h4: 1.286rem;
$h5: 1.07rem;
$h6: 1rem;
$p: 1rem;

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  font-family: 'Almarai', sans-serif;
  color: var(--text-color);
  overflow-x: hidden;
  scroll-behavior: auto !important;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background: url('../../public/assets/images/background.png');
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
  color: var(--text-color);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {}

a {
  text-decoration: none;
  color: var(--text-color);
}

ul {
  list-style: none;
}

#root,
#__next {
  isolation: isolate;
}

/********************************************************************************/

/********************* Adjusting Font Sizes For All Screens *********************/
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 5px 0;
}

h1 {
  font-size: $h1;
}

h2 {
  font-size: $h2;
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}

p {
  line-height: 25px;
  font-size: $p;
}

/****** Medium Screen ******/
@include md {

  span,
  p {
    font-size: 15px;
  }
}

@include xs {

  span,
  p {
    font-size: 14px;
  }
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;
  object-fit: cover;
}

/*****************************************\ Selection Reset \**************************************/

::selection {
  background: var(--main-color);
  color: #fff;
}

::-moz-selection {
  background: var(--main-color);
  color: #fff;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
  border-radius: 0px;
  background-color: var(--main-color);
}

#ar {
  * {
    direction: rtl;
    text-align: right;
  }
}

#en {
  * {
    direction: ltr;
    text-align: left;
  }
}

// HOME MULTI CAROUSEL COMPONENT STYLES
.react-multiple-carousel__arrow--left {
  left: -10px !important;
}

.react-multiple-carousel__arrow--right {
  right: -10px !important;
}

// QUICK VIEW MULTI CAROUSEL COMPONENT STYLES
.thumbnails {
  .react-multiple-carousel__arrow--left {
    background-color: var(--secondary-background);
    height: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;

    &::before {
      font-size: 15px;
      font-weight: bold;
      border-radius: 0;
      text-align: center !important;
      color: var(--text-color);
      margin: 2px;
      left: -2px;
    }
  }

  .react-multiple-carousel__arrow--right {
    background-color: var(--secondary-background);
    height: 100%;
    border-radius: 0;
    padding: 0;
    width: 5px;

    &::before {
      font-size: 15px;
      font-weight: bold;
      border-radius: 0;
      text-align: center !important;
      color: var(--text-color);
      right: -2px;
    }
  }
}

// CATEGORY PAGE SLIDER GLOBAL STYLES
.MuiSlider-track {
  height: 6px;
}

// PAGINATION SELECTED PAGE GLOBAL STYLES
.pagination {
  button {
    box-shadow: none;

    &.Mui-selected {
      background-color: var(--main-color);
      color: #fff;
      border: 1px solid var(--main-color);

      &:hover {
        background-color: var(--main-color);
        color: #fff;
        border: 1px solid var(--main-color);
      }
    }

    &:hover {
      background-color: var(--main-color);
      color: #fff;
      border: 1px solid var(--main-color);
    }
  }

  @include xs {
    transform: scale(0.9);
  }
}

// SWEET ALERT GLOBAL STYLES
body.swal2-shown {
  padding: 0 !important;
}

button.swal2-confirm {
  background-color: var(--main-color);
  padding: 10px 20px;
  border: 1px solid var(--main-color);
  outline: none;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 5px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: var(--main-color);
  }
}

button.swal2-cancel {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 10px 20px;
  position: relative;
  top: 3px;
  border-radius: 20px;
  font-size: 17px;
  margin: 0 5px;
  font-weight: 500;
  cursor: pointer;
  color: var(--main-color);

  &:hover {
    background-color: #fff;
    color: var(--main-color);
    text-decoration: underline;
  }
}

@include sm {
  .swal2-popup {
    .swal2-icon {
      transform: scale(0.9);
    }

    h2 {
      font-size: 24px;
    }

    div {
      font-size: 18px;
    }
  }
}

@include xs {
  .swal2-popup {
    .swal2-icon {
      transform: scale(0.8);
    }

    h2 {
      font-size: 20px;
    }

    div {
      font-size: 17px;
    }
  }
}

.Toastify__toast-container {
  z-index: 99999;
}

// MUI5 DATE PICKER GLOBAL STYLES
.MuiCalendarPicker-root {
  background-color: var(--secondary-background);

  button {
    background-color: var(--default-background);
    color: var(--text-color);
  }

  span {
    color: var(--text-color);
  }
}

.rdrCalendarWrapper {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 9;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.ar .rdrCalendarWrapper {
  right: unset;
  left: 0;
  direction: ltr !important;

  & * {
    direction: ltr !important;
  }
}

.control-dots {
  display: flex;
  align-items: center;

  @include sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dot {
    width: 10px !important;
    height: 10px !important;
    background-color: #ccc;
    border: 2px solid rgb(162, 162, 162);

    &.selected {
      background-color: var(--main-color);
      border: 2px solid var(--main-color);
      width: 40px !important;
      border-radius: 10px;
    }
  }
}

.carousel {
  direction: ltr !important;

  .slider-wrapper {
    direction: ltr !important;

    .slider {

      direction: ltr !important;
    }
  }
}

h3.label,
h2.label,
h1.label {
  margin-bottom: 30px;
  text-align: center !important;
  font-weight: 600;

  img {
    width: 45px;
    height: 45px;
    margin: 10px auto;
    opacity: 0.8;
    transform: rotate(-20deg);
  }

  i {
    font-size: 2rem;
    color: rgb(185, 185, 185);
    text-align: center !important;

    @include sm {
      font-size: 1.5rem;
    }
  }

  p {
    text-align: center !important;
    font-size: 1.3rem;
    margin-bottom: 50px;

    @include sm {
      font-size: 1.1rem;
    }

    @include xs {
      font-size: 1rem;
    }
  }

  @include sm {
    font-size: 1.2rem;
  }
}

.blob {
  position: absolute;
  top: 10%;
  left: 10%;
  z-index: 1;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background-color: var(--main-color);
  margin: auto;
  animation: blob 5s linear infinite;

  @include lg {
    width: 350px;
    height: 350px;
  }

  @include md {
    width: 300px;
    height: 300px;
  }

  @include sm {
    width: 350px;
    height: 350px;
  }

  @include xs {
    width: 300px;
    height: 300px;
  }

  @keyframes blob {

    0%,
    100% {
      border-radius: 64% 36% 27% 73% / 55% 58% 42% 45%;
    }

    25% {
      border-radius: 73% 27% 26% 38% / 73% 36% 64% 27%;
    }

    50% {
      border-radius: 28% 72% 44% 56% / 49% 40% 60% 51%;
    }

    75% {
      border-radius: 40% 60% 70% 30% / 47% 62% 38% 53%;
    }
  }
}

.quarterContainer {
  .quarter {
    position: absolute;
    z-index: 2;
    bottom: -90px;
    left: -90px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #738698;

    &.second {
      background-color: #738698;
    }

    &.en {
      left: unset;
      right: -90px;
    }
  }

  .movingQuarter {
    position: absolute;
    z-index: 1;
    bottom: -80px;
    left: -80px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #d2e1f1;
    transition: all 0.4s ease-in-out;

    &.second {
      background-color: #d2e1f1;
    }

    &.en {
      left: unset;
      right: -80px;
    }
  }

  h2,
  h3,
  h4,
  h6,
  p,
  span {
    transition: all 0.3s ease-in-out;
    text-align: center !important;
  }

  p,
  span {
    font-size: 0.9rem !important;
  }

  &:hover {
    color: #fff;

    span {
      color: #fff !important;
    }

    .movingQuarter {
      bottom: 0px;
      left: 0;
      border-radius: 0%;
      width: 100%;
      height: 100%;
      background-color: var(--dark-color);

      &.en {
        left: unset;
        right: 0;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 0px;

  .page-item {
    pointer-events: unset;
    cursor: pointer;

    .page-link {
      color: var(--main-color);
      border: 1px solid var(--main-color);
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0 3px;

      &:focus {
        background-color: #fff;
        box-shadow: unset;
      }

      &:hover {
        background-color: var(--main-color);
        color: #fff;
        box-shadow: unset;
      }

      &.active {
        background-color: var(--main-color);
        color: #fff !important;
        box-shadow: unset;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

// ACTIVE LINKS
ul {
  li {
    a.active {
      color: var(--main-color) !important;
    }
  }
}

// DATE PICKER STYLES
.react-datepicker__triangle {
  transform: translate(200px, 0) !important;
}

.react-datepicker__day--selected {
  background-color: var(--main-color);
  color: #fff;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 100px 0;

  img {
    width: 150px;
  }

  p {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  @include sm {
    img {
      width: 100px;
    }

    p {
      font-size: 1rem;
    }
  }
}

.webcontent {
  margin-top: 10px !important;
}

.page_speed_887208001,
.page_speed_600929819 {
  color: var(--main-color) !important;
}

span[style*="color: rgb(255, 102, 0)"] {
  color: var(--main-color) !important;
}

.container {
  width: auto !important;
}