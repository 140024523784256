@import '../../../styles/mixins';

.platforms {
  margin: 50px 0 100px;
  .carousel {
    padding: 10px 0;
    direction: ltr !important;
    
    .slide {
      text-align: center !important;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      cursor: pointer;
      background-color: #fff;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      img {
        width: 120px;
        height: 120px;
        object-fit: contain;
        pointer-events: none;
      }
    } 
  }
}