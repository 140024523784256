@import '../../styles/mixins';

.questions {
  margin: 50px 0 100px;

  h1 {
    margin-bottom: 80px !important;
    p {
      font-size: 1.6rem !important;
    }
  }

  .accordion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .question {
      grid-column: span 1;
      overflow: hidden;
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:focus, &:hover, &:active {
          background-color: unset;
          box-shadow: unset;
          border: unset;
        }
        i {
          font-size: 1.4rem;
          color: var(--main-color);
        }
        &:after {
          content: none;
        }
      }
      @include sm {
        grid-column: span 2;
      }
    }
  }
}