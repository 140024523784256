.universityDetails {
  margin-top: 100px;

  .specs {
    margin-bottom: 50px;
    h3 {
      margin-bottom: 30px;
    }
    ul {
      li {
        display: inline-block;
        padding: 8px 20px;
        font-weight: 600;
        font-size: 0.95rem;
        cursor: pointer;
        &.selected {
          background-color: var(--main-color);
          color: #fff;
          border-radius: 20px;
        }
      }
    }
  }
}