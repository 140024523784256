@import '../../styles/mixins';
@import '../../styles/extends';

.news {
  margin: 50px 0 100px;

  .newsHead {
    margin-bottom: 60px;
    .search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      margin: 0 auto;
      input {
        width: 100%;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 0 5px 5px 0;
        padding: 10px 20px;
        font-size: 1.1rem;
        outline: none;
        &.en {
          border-radius: 5px 0 0 5px;
        }
      }
      button {
        @extend %mainBtn;
        border-radius: 5px 0 0 5px;
        padding: 10px 40px;
        font-size: 1rem;
        &.en {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
  }
  
  .singleNews {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    padding: 15px;
    margin-bottom: 30px;
    cursor: pointer;
    background-color: #fff;
    grid-column: span 3;
    @include lg {
      grid-column: span 4;
    }
    @include md {
      grid-column: span 6;
    }
    @include sm {
      max-width: 350px;
      margin: 10px auto;
      grid-column: span 6;
    }
    @include xs {
      grid-column: span 12;
    }

    img {
      height: 200px;
      position: relative;
      z-index: 3;
      border-radius: 5px;
    }

    .info {
      padding: 15px 0 20px;
      position: relative;
      z-index: 3;
      h6 {
        font-weight: 600;
      }
      p {
        opacity: 0.8;
        font-size: 0.9rem;
      }
      span {
        opacity: 0.8;
        font-size: 0.8rem;
        i {
          color: var(--main-color);
        }
        span {
          margin: 0 5px;
        }
      }
    }
  }
}