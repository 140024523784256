@import '../../../styles/mixins';

.features {
  margin: 100px 0;

  .text {
    margin-bottom: 50px;
    @include sm {
      h1 {
        font-size: 1.2rem;
      }
    }
  }

  .part {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    img {
      width: 80px;
      margin-bottom: 20px;
    }
    i {
      font-size: 2.5rem;
    }
    &:hover {
      background-color: var(--dark-color);
      color: #fff;
    }
    h6 {
      font-size: 1.1rem;
    }
    @include sm {
      h6 {
        font-size: 0.9rem;
      }
    }
  }
}