@import '../../../styles/mixins';
@import '../../../styles/extends';

.details {
  .news {
    padding: 15px 15px 25px 15px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    margin-bottom: 30px;
    @include sm {
      margin: 0 auto 30px;
      .info {
        h4 {
          font-size: 1rem !important;
        }
        p {
          font-size: 0.8rem !important;
        }
      }
    }
    .badge {
      position: absolute;
      top: 0px;
      left: 10%;
      z-index: 3;
      background-color: #f44336;
      color: #fff;
      padding: 15px 10px;
      border-radius: 5px;
      font-size: 0.9rem;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.en {
        left: unset;
        right: 10%;
      }
    }
    .newsImage {
      border-radius: 10px;
      margin-bottom: 25px;
      position: relative;
      z-index: 2;
      min-height: 300px;
    }
    .info {
      position: relative;
      z-index: 2;
      span {
        font-size: 0.9rem;
        margin-left: 20px;
        &.en {
          margin-left: unset;
          margin-right: 20px;
        }
        i {
          padding: 0 3px;
          color: var(--main-color);
        }
      }
      h4 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 1.2rem;
      }
      p {
        opacity: 0.8;
        font-size: 0.9rem;
      }
    }

    .tags {
      border: 1px solid rgb(226, 226, 226);
      border-right: none;
      border-left: none;
      margin: 20px 0;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include sm {
        display: block;
      }
      .part {
        display: flex;
        align-items: center;
        justify-content: center;
        &.partOne {
          @include sm {
            display: block;
            span {
              font-size: 0.7rem;
            }
          }
        }
        span { 
          font-size: 0.8rem;
          padding: 6px 15px;
          background-color: var(--main-color);
          color: #fff;
          border-radius: 20px;
          margin: 0 5px;
          border: 1px solid var(--main-color);
          cursor: pointer;
          &:hover {
            background-color: transparent;
            color: var(--main-color);
          }
        }

        i {
          width: 35px;
          height: 35px;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
          margin: 0 5px;
          display: grid;
          place-content: center;
          color: var(--main-color);
          cursor: pointer;
          @include sm {
            margin-top: 20px;
          }
          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
        }
      }
    }

  }
}