@import '../../../styles/mixins';

.universities {
  margin: 50px 0 100px;

  .carousel {
    padding: 10px 0;
    direction: ltr !important;
    
    .slide {
      text-align: center !important;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      cursor: pointer;
      background-color: #fff;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      &:hover {
        background-color: var(--dark-color);
        color: #fff;
      }
      img {
        width: 100%;
        height: 100px;
        margin-bottom: 10px;
        object-fit: cover;
        pointer-events: none;
        border-radius: 5px;
      }
      p {
        font-size: 0.95rem;
      }
    } 
  }

  .description {
    width: 80%;
    margin: 30px auto;
    margin-top: 30px;
    border: 2px dashed var(--border-color);
    padding: 20px;
    border-radius: 5px;
    position: relative;
    & > i {
      position: absolute;
      left: 10px;
      top: 10px;
      display: grid;
      place-content: center;
      font-size: 1rem;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--border-color);
      z-index: 3;
      cursor: pointer;
      &.en {
        left: unset;
        right: 10px;
      }
      &:hover {
        background-color: var(--dark-color);
        color: #fff;
      }
    }

    .smallSlide {
      text-align: center !important;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      background-color: #fff;
    }
    h6 {
      font-weight: 600;
      margin-bottom: 10px;
      i {
        font-size: 1.5rem;
      }
      span {
        margin: 0 10px;
      }
    }
    p {
      opacity: 0.8;
    }
  }
}