// IMPORTING GLOBAL VARIABLES AND MIXINS
@import "../../../styles/mixins.scss";
@import "../../../styles/extends.scss";

.lang {
  button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 0.9rem;
    position: relative;
    z-index: 999999999;
    &:hover, &:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
  ul {
    padding: 10px !important;
    position: relative;
    z-index: 999999999;
    li {
      display: block !important;
      &:hover {
        color: var(--main-color) !important;
      }
    }
  }
}
