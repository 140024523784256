@import '../../styles/mixins';
@import '../../styles/extends';

.registered {
  margin: 70px 0 100px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  i {
    font-size: 4rem;
    color: rgb(53, 168, 53);
  }

  p {
    text-align: center !important;
  }

  button {
    @extend %mainBtn;
    margin-top: 10px;
  }
}