@import '../../../styles/mixins';
@import '../../../styles/extends';

.contact {
  margin: 50px 0 100px;

  .row {
    @include sm {
      flex-direction: column-reverse;
    }
  }

  .form {
    width: 500px;
    margin: auto;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    background-color: #fff;
    @include md {
      width: auto;
    }
    @include sm {
      width: 90%;
    }
    @include xs {
      width: 100%;
    }
    h4 {
      text-align: center !important;
      color: var(--main-color);
      margin-bottom: 40px;
    }
    input {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      padding: 10px 15px;
      border: none;
      border: 1px solid rgb(221, 221, 221);
      border-radius: 5px;
      outline: none;
      background-color: transparent;
      font-size: 0.9rem;
    }
    label {
      margin-bottom: 10px;
    }

    .btn {
      text-align: center !important;
      button {
        @extend %mainBtn;
      }
    }
  }

  .image {
    margin-bottom: 30px;
    position: relative;
    img {
      height: 600px;
      width: 100%;
      min-width: 200px;
      object-fit: contain;
      position: relative;
      z-index: 2;
      @include sm {
        height: 400px;
      }
    }
  }
}