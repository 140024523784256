@import '../../../styles/extends';

.programDetailsUniversities {
  margin-bottom: 100px;
  h2 {
    margin-bottom: 30px;
  }
  .block {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
  }
  div {
    text-align: center !important;
    margin-bottom: 10px;
    p {
      color: var(--main-color);
      text-align: center !important;
    }
    span {
    }
    button {
      @extend %mainBtn;
      padding: 5px 15px;
      font-size: 0.8rem;
    }
  }
}