@import '../../styles/mixins';
@import '../../styles/extends';

.login {
  margin: 50px 0 100px;

  .form {
    width: 600px;
    background-color: #fff;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include sm {
      width: 450px;
    }
    @include xs {
      width: 330px;
    }
    input {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      padding: 10px 20px;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #ccc;
      outline: none
    }
    button {
      @extend %mainBtn;
      width: 50%;
    }
  }
}