@import '../../../styles/mixins';
@import '../../../styles/extends';

.personalSection {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 40px 20px 20px 20px;
  border-radius: 5px;
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.05);
      border: 1px solid #ccc;
      @include sm {
        width: 110px;
        height: 110px;
      }
    }
    .info {
      margin: 0 20px;
      h5 {
        font-size: 1.3rem;
        font-weight: bold;
        @include sm {
          font-size: 1.2rem;
        }
      }
      button {
        @extend %mainBtn;
        padding: 5px 20px;
        font-size: 0.8rem;
        margin: 0 -10px;
        @include sm {
          padding: 3px 15px;
          margin: 0 -5px;
        }
      }
    }
  }

  .details {
    margin-top: 30px;
    .one {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      padding: 5px 20px;
      margin-bottom: 10px;
      p {
        grid-column: span 1;
        color: var(--main-color);
        font-size: 0.95rem;
      }
      span {
        font-weight: 600;
        grid-column: span 11;
        margin: 0 30px;
      }
    }
  }
}