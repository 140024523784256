@import '../../../styles/mixins';
@import '../../../styles/extends';

.universityHead {
  margin-bottom: 100px;
  height: 70vh;
  display: flex;
  align-items: center;

  .row {
    @include md {
      flex-direction: column-reverse;
    }
  }

  .textSide {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 40px;

    img {
      width: 100px;

      @include sm {
        width: 80px;
      }
    }

    h2 {
      margin-bottom: 15px;
      font-weight: bold;

      @include sm {
        font-size: 1.2rem;
      }
    }

    li {
      color: #fff !important;
      font-size: 1.3rem !important;
      opacity: 0.9;
    }

    p {
      opacity: 0.9;

      p {
        span {
          color: #fff !important;
          font-size: 1.3rem !important;
        }
      }

      @include sm {
        font-size: 1rem;
      }

      @include xs {
        font-size: 0.9rem;
      }
    }
  }

  .imageSide {
    height: 70vh;
    position: relative;

    @include md {
      height: 50vh;
      margin-bottom: 30px;
    }

    img {
      width: 100%;
    }
  }

  button {
    @extend %mainBtn;
    margin-top: 15px;
  }
}