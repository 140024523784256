.programDetails {
  .container {
    width: auto !important;
  }
  .head {
    margin-top: 20px;
    margin-bottom: 50px;
    img {
      width: 100%;
      height: 450px;
      border-radius: 5px;
    }
  }
}